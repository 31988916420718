import ValueTypeToggleBtn from 'common/components/StopsValueTypeToggleBtn';
import { slippageTypes } from 'common/constants/index';
import { STOPS_VALUE_TYPES } from 'modules/QuantBuilder/config/index';
import HoverMessage from 'common/components/HoverMessage';
import React, { useContext, useEffect, useState } from 'react';
import { OrderContext } from '../OrderStore/index';

const SlippageInputDetails = () => {
  const { slippage, onHandleSlippage, slippageType } = useContext(OrderContext);
  const [currentSlippage, setCurrentSlippage] = useState(slippage);
  const [currentSlippageType, setCurrentSlippageType] = useState(slippageType);

  useEffect(() => { setCurrentSlippage(slippage); }, [slippage]);
  useEffect(() => { setCurrentSlippageType(slippageType); }, [slippageType]);

  const { PERCENT, PRICE } = STOPS_VALUE_TYPES;

  return (
    <div className="slippage-wrapper d-flex align-items-center">
      <span className="tx-12">Slippage</span>
      <HoverMessage
        title="Slippage"
        message="Slippage is the gap between your intended order price and the actual execution price.
        It is mandatory, with a minimum of 1.5 for option buying and 0.5 for option selling"
      >
        <span className="material-icons-outlined info-icon ml-1 mr-2">
          info
        </span>
      </HoverMessage>
      <div className="input-group overall-stops">
        <ValueTypeToggleBtn
          valueType={currentSlippageType === slippageTypes.percentage ? PERCENT : PRICE}
          onValueTypeChange={(newType) => {
            if (newType === PRICE) return setCurrentSlippageType(slippageTypes.price);

            return setCurrentSlippageType(slippageTypes.percentage);
          }}
        />
        <input
          type="number"
          value={currentSlippage || ''}
          className="form form-control form-control-sm"
          onChange={(event) => { setCurrentSlippage(event.target.value); }}
        />
      </div>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm ml-2"
        onClick={() => onHandleSlippage(currentSlippage, currentSlippageType)}
      >
        Go
      </button>
    </div>
  );
};

SlippageInputDetails.propTypes = {};

export default SlippageInputDetails;
