import React from 'react';

const FeaturesSection = () => (
  <section className="features-section section overflow-hidden px-0" id="features">
    <div className="container-fluid bg-white">

      <div className="section-head text-center">
        <h2 className="font-weight-bold">What are the features of the product?</h2>
        <p>Features are highlighted below.</p>
      </div>

      <div className="row align-items-center section">
        <div className="col-md-12 col-lg-6 order-2 order-lg-1">
          <div className="feature-content contant-spacing">
            <h3>Strategy Templates</h3>
            <ul className="list-unstyled list">
              <li>
                <div>
                  Directional:
                  {' '}
                  <span className="font-weight-semi">Bull Call spread / Bear Put Spread</span>
                </div>
              </li>
              <li>
                <div>
                  Non-directional Strategies:
                  {' '}
                  <span className="font-weight-semi">Straddle / Strangle/ Iron Condor</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 mb-5 mb-lg-0 order-1 order-lg-2">
          <div className="feature-img img-spacing">
            <img
              alt="img2"
              src="/landing-page-img/feature-1.png"
              className=""
            />
          </div>
        </div>
      </div>

      <div className="row align-items-center section">
        <div className="col-md-12 col-lg-6 mb-5 mb-lg-0">
          <div className="feature-img contant-spacing">
            <img
              alt="img2"
              src="/landing-page-img/quantman-indcators.png"
              className=""
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-6 col-lg-6">
          <div className="feature-content contant-spacing">
            <h3>Create Your Own Strategy</h3>
            <ul className="list-unstyled list">
              <li>
                <div>
                  Tweak these strategies using our massive
                  set of customizable indicators or other custom signals like
                  <br />
                  <ol className="font-weight-semi">
                    <li>Days to expiry</li>
                    <li>Technical indicators</li>
                    <li>Opening range or candle at specified period.</li>
                    <li className="mb-0">Day of week / Time of Day.</li>
                  </ol>
                </div>
              </li>
              <li>
                Intraday and Positional, both supported.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row align-items-center section">
        <div className="col-md-12 col-lg-6 col-lg-6  order-2 order-lg-1">
          <div className="feature-content contant-spacing">
            <ul className="list-unstyled list pl-0 pl-md-2">
              <li>
                <div>
                  Choose Options to place enter based on
                  <br />
                  <ol className="font-weight-semi">
                    <li>Spot Based Strike Index</li>
                    <li>Future Based Strike Index</li>
                    <li>Premium</li>
                    <li>Strike Price</li>
                  </ol>
                </div>
              </li>
              <li>
                We support both Weekly and Monthly expiry cycles.
              </li>
              <li>
                Futures / Options / Equity of Indexes and all Stocks.
              </li>
              <li>
                Build multiple cases with different transaction Legs and Entry / Exit signals.
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-12 col-lg-6 col-lg-6 mb-5 mb-lg-0  order-1 order-lg-2">
          <div className="feature-img img-spacing">
            <img
              alt="img2"
              src="/landing-page-img/quantman-transaction.png"
              className=""
            />
          </div>
        </div>
      </div>

      <div className="row align-items-center section">
        <div className="col-md-12 col-lg-6 mb-5 mb-lg-0">
          <div className="feature-img contant-spacing">
            <img
              alt="img2"
              src="/landing-page-img/feature-4.png"
              className=""
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-6">
          <div className="feature-content contant-spacing">
            <ul className="list-unstyled list pl-0 pl-md-2">
              <li>
                Candle / Heikin Ashi
              </li>

              <li>
                SuperTrend / RSI / ATR / SMA / ... (27 more)
              </li>

              <li>
                Volume / Open interest based signal generation
              </li>

              <li>
                BullishHammer / BullishEngulfing / BearishMarubozu / BearishSpinningTop
              </li>

              <li>
                Price Ratio Standard Deviation Indicator
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FeaturesSection;
