import wrappedFetch from './base';

export const fetchOrders = _.memoize(({ runId, jobId, orderConfirmationId }) => {
  return wrappedFetch(`/runs/${runId}/jobs/${jobId}/order_confirmations/${orderConfirmationId}/orders`)
    .then((response) => response.json());
}, ({ runId, jobId, orderConfirmationId }) => `${runId}_${jobId}_${orderConfirmationId}`);

export const fetchOrdersByOrderId = _.memoize(({ jobId, orderIds }) => {
  const encodedOrderIds = _.map(orderIds, (id) => encodeURIComponent(id)).join(',');
  return wrappedFetch(`runs/${jobId}/orders?run_type=live&by_order_ids=${encodedOrderIds}`)
    .then((response) => response.json());
}, ({ jobId, orderIds }) => `${jobId}_${orderIds}`);

export const updateSignalConfirmation = ({
  runId,
  jobId,
  orderConfirmationId,
  verificationToken: token,
  status,
  ordersPrice
}) => {
  const params = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ token, status, ordersPrice })
  };

  return wrappedFetch(
    `/runs/${runId}/jobs/${jobId}/order_confirmations/${orderConfirmationId}/update_confirmation`,
    params
  ).then((response) => response.json());
};
