import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { setRootValue } from 'v2/modules/withRunForm/redux/actions';
import { ORDER_TYPES } from 'v2/common/constants/index';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { onChangeOrderType } from '../../events/orderType';

const OrderType = () => {
  const dispatch = useDispatch();
  const orderType = useSelector((state) => getSelectorBasedStoreValue(state, 'orderType'));
  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const quantConfig = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig'));
  const onUpdateOrderType = (value) => dispatch(
    setRootValue(
      onChangeOrderType({
        quantConfig,
        orderType: value,
        runType,
      })
    )
  );

  const renderSelectOption = (orderTypeArg) => {
    if (orderTypeArg.value === ORDER_TYPES.oneClick.value) return null;
    return (
      <ToggleButton
        key={orderTypeArg.value}
        className={`btn btn-secondary ${orderType === orderTypeArg.value ? 'active' : ''}`}
        bsPrefix="btn btn-sm btn-outline-primary btn-lightness text-white"
        value={orderTypeArg.value}
        onClick={() => onUpdateOrderType(orderTypeArg.value)}
      >
        {orderTypeArg.displayName}
      </ToggleButton>
    );
  };
  return (
    <div className="order-type-input">
      <label className="label_style">Order Type</label>
      <ToggleButtonGroup
        bsPrefix="btn-group btn-group-sm btn-group-toggle"
        data-toggle="buttons"
        type="radio"
        size="sm"
        name="group-selector"
        value={orderType}
      >
        {_.map(Object.values(ORDER_TYPES), (orderTypeArg) => renderSelectOption(orderTypeArg))}
      </ToggleButtonGroup>
    </div>
  );
};

export default OrderType;
