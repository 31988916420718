import { SEGMENT_CONFIG } from 'v2/common/constants/index';
import {
  schemaShouldShowConfigTypes,
  schemaTypes,
} from '../outputSchema/config';
import { pipeInputSchemaTypes } from './config';

const configuration = {
  type: 'yesterdayAVWAP',
  defaults: {
    chartConfig: {
      type: 'candle',
      boxUnitSize: 0,
      boxPercentage: 0,
      volumeInterval: '3 minutes',
    },
    type: 'yesterdayAVWAP',
    name: 'yesterdayAVWAP',
    config: {
      candleInterval: '3 minutes',
      valuePaths: ['item0.equity'],
      multiplier: 1,
      anchorBy: 'high',
    },
  },
  inputSchema: [
    {
      name: 'anchorBy',
      label: 'Anchor By',
      type: pipeInputSchemaTypes.select,
      options: ['high', 'low'],
    },
  ],
  searchTerms: ['Yesterday AVWAP'],
  configFormOptions: {
    valuePathEditable: true,
    configFieldOptions: {},
    valuePathSupportedSegments: [SEGMENT_CONFIG.equity, SEGMENT_CONFIG.future],
  },
  buildOutputSchema: ({ type, name }) => {
    const properties = ['vwap', 'upperBand', 'lowerBand'];

    return _.map(properties, (property) => {
      return {
        name: `${name}.${property}`,
        type: schemaTypes.number,
        indicatorType: type,
        category: 'indicator',
        label: name === property ? name : `${name} ${property}`,
        shouldShowConfigs: [
          schemaShouldShowConfigTypes.adjustmentLeftOperand,
          schemaShouldShowConfigTypes.adjustmentRightOperand,
          schemaShouldShowConfigTypes.entryLeftOperand,
          schemaShouldShowConfigTypes.entryRightOperand,
          schemaShouldShowConfigTypes.exitLeftOperand,
          schemaShouldShowConfigTypes.exitRightOperand,
          schemaShouldShowConfigTypes.intentValuePath,
        ],
        priority: 70,
        schemaName: name,
      };
    });
  },
  isEnabled: window?.isAdmin,
};
export default configuration;
