import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Tooltip } from 'react-bootstrap';

import consumer from 'channels/consumer';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import notificationPropType from './propTypes/notificationPropType';
import Notification from './Notification';

const propTypes = {
  notifications: PropTypes.arrayOf(notificationPropType).isRequired,
  newCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  userRoomId: PropTypes.string.isRequired,
};

const defaultProps = {};
const I18N_SCOPE = { scope: 'notifications' };

export default class NotificationPanel extends React.Component {
  constructor(props) {
    super(props);

    const { notifications, newCount, totalCount } = this.props;

    this.notificationPanelRef = React.createRef();
    this.state = {
      notifications,
      newCount,
      totalCount,
      isOpened: false,
      isLoadingMore: false,
    };
  }

  componentDidMount() {
    const { userRoomId } = this.props;
    const subscriptionParams = { channel: 'NotificationsChannel', room: userRoomId };
    const callbacks = {
      connected: () => console.debug('NotificationsSocket connected.'),
      disconnected: () => console.debug('NotificationsSocket disconnected.'),
      received: this.onNewNotification,
    };
    this.subscribedRoom = consumer.subscriptions.create(subscriptionParams, callbacks);

    this.signalConfirmationUpdateRoom = consumer.subscriptions.create({
      channel: 'NotificationsChannel',
      room: `signal_confirmation_${userRoomId}`
    }, {
      connected: () => { },
      disconnected: () => { },
      received: (orderConfirmation) => {
        const { id, status } = orderConfirmation;
        this.onSignalConfirmationUpdate(id, status);
      }
    });

    document.addEventListener('click', this.handleDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
    if (this.subscribedRoom) {
      consumer.subscriptions.remove(this.subscribedRoom);
    }
    if (this.signalConfirmationUpdateRoom) {
      consumer.subscriptions.remove(this.signalConfirmationUpdateRoom);
    }
  }

  handleDocumentClick = (event) => {
    const { isOpened } = this.state;
    const isClickedInsideNotificationPanel = this.notificationPanelRef.current.contains(event.target);
    const isOrderDetailsModalOpen = document.getElementsByClassName('order-transaction-panel-modal').length;
    // order modal

    if (isOpened && !isClickedInsideNotificationPanel && !isOrderDetailsModalOpen) {
      this.setState({ isOpened: false });
    }
  }

  onToggleOpen = (isOpened) => {
    this.setState({ isOpened });
  }

  onNewNotification = (newNotification) => {
    const { totalCount, newCount, notifications } = this.state;
    this.setState({
      notifications: [newNotification].concat(notifications),
      newCount: newCount + 1,
      totalCount: totalCount + 1,
    });

    const eventType = newNotification.event === 'errored' ? TOASTIFY_TYPES.ERROR : TOASTIFY_TYPES.SUCCESS;
    reactToastify(newNotification.message, eventType);
  }

  onMarkNotificationAsOld = (notification) => {
    const { newCount, notifications } = this.state;
    // We are mutating the state. Not good. Should revisit this.
    notification.is_new = false; // eslint-disable-line no-param-reassign

    axios({
      url: `/notifications/${notification.id}.json`,
      method: 'PUT',
      data: { notification: { is_new: 'false' } },
    });
    // compromise mutating state. Ok for now. :|
    this.setState({ notifications, newCount: newCount - 1 });
  }

  onDeleteNotification = (notificationToDelete) => {
    const { notifications, totalCount, newCount } = this.state;
    const newNotifications = _.filter(notifications, (n) => (n.id !== notificationToDelete.id));

    axios({ url: `/notifications/${notificationToDelete.id}.json`, method: 'DELETE' });

    this.setState({
      notifications: newNotifications,
      totalCount: totalCount - 1,
      newCount: newCount - (notificationToDelete.is_new ? 1 : 0)
    });
  }

  onMarkAllAsOld = () => {
    const { notifications } = this.state;

    _.each(notifications, (notification) => {
      notification.is_new = false; // eslint-disable-line no-param-reassign
    });

    axios.post('/notifications/mark_all_as_old', { notification_id: _.first(notifications).id });

    this.setState({ notifications, newCount: 0 });
  }

  onDeleteAll = () => {
    const { notifications } = this.state;
    const firstNofitication = _.first(notifications);
    if (_.isEmpty(firstNofitication)) {
      return reactToastify('Something went Wrong, Please try again', TOASTIFY_TYPES.ERROR);
    }

    axios.post('/notifications/destroy_all', { notification_id: _.first(notifications).id });

    return this.setState({ notifications: [], totalCount: 0, newCount: 0 });
  }

  loadMore = () => {
    const { notifications } = this.state;
    const offsetNotificationId = _.chain(notifications).last().get('id', '');

    this.setState({ isLoadingMore: true });
    // Refer NotificationsController.rb for details on why we are using notificationId for offset
    axios.get('/notifications.json', { params: { offsetNotificationId, locale: I18n.locale } })
      .then((response) => {
        const olderNotifications = _.get(response, 'data', []);
        this.setState({ notifications: notifications.concat(olderNotifications) });
      })
      .finally(() => this.setState({ isLoadingMore: false }));
  }

  onSignalConfirmationUpdate = (notifiableId, status) => {
    const { notifications } = this.state;
    const signalConfirmation = _.find(notifications, ['notifiable_id', notifiableId]);
    const isNew = _.get(signalConfirmation, 'is_new');

    _.set(signalConfirmation, ['details', 'status'], status);
    this.setState({ notifications: [...notifications] }, () => {
      if (isNew) { this.onMarkNotificationAsOld(signalConfirmation); }
    });
  }

  renderLoadMore() {
    const { notifications, totalCount, isLoadingMore } = this.state;
    if (notifications.length === 0 || notifications.length >= totalCount) {
      return null;
    }

    return (
      <div className="load-more-container">
        {isLoadingMore
          ? <div className="spinner-border text-primary" />
          : (
            <button
              type="button"
              className="btn btn-light"
              onClick={this.loadMore}
            >
              {I18n.t('load_more', I18N_SCOPE)}
            </button>
          )}
      </div>
    );
  }

  renderNotifications = () => {
    const { notifications } = this.state;

    return _.map(notifications, (notification) => (
      <Notification
        key={notification.id}
        notification={notification}
        onDelete={this.onDeleteNotification}
        onMarkAsOld={this.onMarkNotificationAsOld}
        signalConfirmation={this.onSignalConfirmationUpdate}
      />
    ));
  }

  renderNotificationButton = () => {
    const { isOpened, newCount } = this.state;
    return (
      <div
        className="nav-link notification-btn"
        key="notification-button"
        onClick={
          () => this.onToggleOpen(!isOpened)
        }
      >
        <i className="material-icons-outlined"> notifications_active </i>
        {newCount !== 0 && (
          <div className="badge badge-success badge-pill">
            <span className="newcount">{newCount > 99 ? '99+' : newCount}</span>
          </div>
        )}
      </div>
    );
  }

  renderNotificationPanel() {
    const { newCount, totalCount, isOpened } = this.state;

    const markAllAsOldBtnContent = newCount > 0
      ? (
        <button
          className="btn btn-sm mark-all"
          type="button"
          onClick={this.onMarkAllAsOld}
          overlay={<Tooltip id="tooltip-mark-all"> </Tooltip>}
        >
          Mark all as Read
        </button>
      ) : null;

    const deleteAllBtnContent = totalCount > 0
      ? (
        <button
          className="btn btn-sm text-danger"
          type="button"
          onClick={this.onDeleteAll}
          overlay={<Tooltip id="tooltip-delete-all"> </Tooltip>}
        >
          Delete all
        </button>
      ) : null;

    return (
      <div
        key="sidebar"
        className={classNames({
          'sidebar notification-panel': true,
          'from-right': true,
          'sidebar-open': isOpened,
        })}
        ref={this.notificationPanelRef}
      >

        <div className="sidebar-head d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            <i className="material-icons-outlined mr-1 align-middle tx-25"> notifications_active </i>
            {I18n.t('notification_label', I18N_SCOPE)}
          </h5>
          <button
            type="button"
            className="action-btn"
            onClick={() => this.onToggleOpen(false)}
          >
            <i className="material-icons-outlined">close</i>
          </button>
        </div>

        <div className="notification-legend d-flex align-items-center justify-content-between">
          <div className="badge badge-secondary tx-12">
            {`${newCount} ${I18n.t('unread_messages', I18N_SCOPE)}`}
          </div>
          <div className="d-flex">
            {markAllAsOldBtnContent}
            {deleteAllBtnContent}
          </div>
        </div>

        <div className="notification-list">
          <ul className="list-group list-group-flush">
            {this.renderNotifications()}
          </ul>
          {this.renderLoadMore()}
        </div>
      </div>
    );
  }

  render() {
    return [
      this.renderNotificationButton(),
      this.renderNotificationPanel(),
    ];
  }
}

NotificationPanel.propTypes = propTypes;
NotificationPanel.defaultProps = defaultProps;
