import { BROKERS, XTS_API_VENDOR, CALLBACK_SUPPORTED_ON_BROKER_DOMAINS } from 'common/constants/index';
import { initiateBrokerCallbackLogin } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

export const reDirectPage = (reDirectData, broker, isRedirectToCallbackPath) => {
  let reDirectUrl = `/auth/${broker}`;
  if (isRedirectToCallbackPath) reDirectUrl += '/callback?';
  else reDirectUrl += '?';

  let index = 0;
  _.each(reDirectData, (data, key) => {
    reDirectUrl += !index ? `${key}=${encodeURIComponent(data)}` : `&${key}=${encodeURIComponent(data)}`;
    index += 1;
  });

  if (CALLBACK_SUPPORTED_ON_BROKER_DOMAINS.includes(broker)) {
    initiateBrokerCallbackLogin(reDirectUrl)
      .then((res) => {
        window.location.href = res?.href;
      })
      .catch(() => {
        reactToastify(
          'Unauthorized, Please Login and continue',
          TOASTIFY_TYPES.ERROR
        );
      });
  } else {
    window.location.href = reDirectUrl;
  }
};

export const getBrokerImage = (broker) => {
  let imageWrapper = '';

  switch (broker) {
    case BROKERS.icici_direct.id:
      imageWrapper += '<img src="/landing-page-img/brokers-img/icici-direct.png" ';
      imageWrapper += `alt=${BROKERS.icici_direct.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-img mb-5" />';
      return imageWrapper;
    case BROKERS.philip_capital.id:
      imageWrapper += `<img src="${BROKERS.philip_capital.image}" `;
      imageWrapper += `alt=${BROKERS.philip_capital.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-img mb-5" />';
      return imageWrapper;
    case BROKERS.zebull.id:
      imageWrapper += '<img src="https://zebu.mynt.in/static/logo/mainlogo.svg" ';
      imageWrapper += `alt=${BROKERS.zebull.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-img mb-5" />';
      return imageWrapper;
    case BROKERS.flattrade.id:
      imageWrapper += `<img src="${BROKERS.flattrade.image}" `;
      imageWrapper += `alt=${BROKERS.flattrade.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-img mb-5" />';
      return imageWrapper;
    // case BROKERS.zerodha.id:
    //   imageWrapper += '<img src="/landing-page-img/brokers-img/kite-connect.svg" ';
    //   imageWrapper += `alt=${BROKERS.zerodha.name} `;
    //   imageWrapper += 'class="brokers-logo mb-3" />';
    //   return imageWrapper;
    case BROKERS.fivepaisa.id:
      imageWrapper += '<img src="/landing-page-img/brokers-logo/5paisalogo.png" ';
      imageWrapper += `alt=${BROKERS.fivepaisa.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.bigul.id:
      imageWrapper += `<img src="${BROKERS.bigul.image}" `;
      imageWrapper += `alt=${BROKERS.bigul.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case XTS_API_VENDOR:
      let imageSource = 'https://encrypted-tbn0.gstatic.com';
      imageSource += '/images?q=tbn:ANd9GcSwr_LzBoU7RjcYjp21df6W-rFBEE9eI2qjEQ&usqp=CAU';
      imageWrapper += `<img src="${imageSource}" `;
      imageWrapper += `alt=${XTS_API_VENDOR} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.goodwill_dealer.id:
      imageWrapper += `<img src="${BROKERS.goodwill_dealer.image}" `;
      imageWrapper += `alt=${BROKERS.goodwill_dealer.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.finvasia.id:
      imageWrapper += '<img src="/images/brokers/shoonya_by_finvasia.png" ';
      imageWrapper += `alt=${BROKERS.finvasia.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.iifl.id:
      imageWrapper += `<img src="${BROKERS.iifl.image}" `;
      imageWrapper += `alt=${BROKERS.iifl.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.upstox.id:
      imageWrapper += `<img src="${BROKERS.upstox.image}" `;
      imageWrapper += `alt=${BROKERS.upstox.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-img mb-5" />';
      return imageWrapper;
    case BROKERS.bnrathi.id:
      imageWrapper += '<img src="/images/brokers/bnrath_full_logo.png" ';
      imageWrapper += `alt=${BROKERS.bnrathi.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.tradeplus.id:
      imageWrapper += `<img src="${BROKERS.tradeplus.image}" `;
      imageWrapper += `alt=${BROKERS.tradeplus.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.paytm.id:
      imageWrapper += `<img src="${BROKERS.paytm.image}" `;
      imageWrapper += `alt=${BROKERS.paytm.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-5" />';
      return imageWrapper;
    case BROKERS.kotak_neo.id:
      imageWrapper += `<img src="${BROKERS.kotak_neo.image}" `;
      imageWrapper += `alt=${BROKERS.kotak_neo.name} `;
      imageWrapper += 'class="brokers-logo kotak-neo-logo" />';
      return imageWrapper;
    case BROKERS.sky_broking.id:
      imageWrapper += `<img src="${BROKERS.sky_broking.image}"`;
      imageWrapper += `alt=${BROKERS.sky_broking.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3 w-100" />';
      return imageWrapper;
    case BROKERS.ac_agarwal.id:
      imageWrapper += `<img src="${BROKERS.ac_agarwal.image}" `;
      imageWrapper += `alt=${BROKERS.ac_agarwal.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.samco.id:
      imageWrapper += `<img src="${BROKERS.samco.image}" `;
      imageWrapper += `alt=${BROKERS.samco.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.indira_securities.id:
      imageWrapper += `<img src="${BROKERS.indira_securities.image}" `;
      imageWrapper += `alt=${BROKERS.indira_securities.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.jainam_broking_retail.id:
    case BROKERS.jainam_broking_prop_a.id:
    case BROKERS.jainam_broking_prop_b.id:
      imageWrapper += `<img src="${BROKERS.jainam_broking_retail.image}" `;
      imageWrapper += `alt=${BROKERS.jainam_broking_retail.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.fair_invest.id:
      imageWrapper += `<img src="${BROKERS.fair_invest.image}" `;
      imageWrapper += `alt=${BROKERS.fair_invest.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.profitmart.id:
      imageWrapper += `<img src="${BROKERS.profitmart.image}" `;
      imageWrapper += `alt=${BROKERS.profitmart.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.trade_smart.id:
      imageWrapper += `<img src="${BROKERS.trade_smart.image}" `;
      imageWrapper += `alt=${BROKERS.trade_smart.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.hdfcsky.id:
      imageWrapper += `<img src="${BROKERS.hdfcsky.image}" `;
      imageWrapper += `alt=${BROKERS.hdfcsky.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.invest_right.id:
      imageWrapper += `<img src="${BROKERS.invest_right.image}" `;
      imageWrapper += `alt=${BROKERS.invest_right.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.kasat.id:
      imageWrapper += `<img src="${BROKERS.kasat.image}" `;
      imageWrapper += `alt=${BROKERS.kasat.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    case BROKERS.rupeezy.id:
      imageWrapper += `<img src="${BROKERS.rupeezy.image}" `;
      imageWrapper += `alt=${BROKERS.rupeezy.name} `;
      imageWrapper += 'class="brokers-logo brokers-logo-sm mb-3" />';
      return imageWrapper;
    default:
      return imageWrapper;
  }
};

export const simpleLoginBrokers = [BROKERS.zerodha.id];

export const shouldHideapisecret = [BROKERS.zebull.id];

export const getBrokerNotes = (broker) => {
  let notesContent = '';
  switch (broker) {
    case BROKERS.zebull.id:
      notesContent += '<div class="my-2 alert alert-warning py-1 px-2"> <b>Note:</b>';
      notesContent += ' It is required to login directly using the link ';
      notesContent += '<a href="https://zebull.in" target="_blank" rel="noreferrer">';
      notesContent += 'https://zebull.in</a>  before login in Quantman. ';
      notesContent += 'Also, do not logout zebull, but it is ok to close the tab directly. </div>';
      return notesContent;
    case BROKERS.flattrade.id:
      notesContent += '<div class="my-2 alert alert-warning py-1 px-2"> <b>Note:</b>';
      notesContent += ' If you logged-in another app using flattrade api after quantman login, ';
      notesContent += ' your session in quantman has expired by flattrade and your orders will fail. </div>';
      return notesContent;
    default:
      return notesContent;
  }
};
