import React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPartialQuantConfig } from '../../redux/actions';
import { getSelectorBasedStoreValue } from '../../redux/selector';

const ORDER_EXECUTION_VERSION = {
  version1: 'legacy',
  version2: 'v2'
};

const propTypes = {};
const defaultProps = {};

const OrderExecutionVersionSelector = () => {
  const isAdmin = _.get(window, 'isAdmin', false);

  if (!isAdmin) return null;

  const orderExecutionVersion = useSelector(
    (state) => getSelectorBasedStoreValue(state, 'quantConfig.orderExecutionVersion')
    || ORDER_EXECUTION_VERSION.version1
  );
  const dispatch = useDispatch();
  const onChangeVersion = (newVersion) => dispatch(
    setPartialQuantConfig({ orderExecutionVersion: newVersion })
  );

  return (
    <div className="run-granularity-container new d-flex flex-column">
      <label className="label_style">
        Order Execution Version
      </label>
      <ToggleButtonGroup
        className="btn-group btn-group-sm btn-group-toggle"
        type="radio"
        size="sm"
        name="options"
        defaultValue={orderExecutionVersion}
        onChange={onChangeVersion}
      >
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          className="btn-lightness track"
          value={ORDER_EXECUTION_VERSION.version1}
        >
          V1
        </ToggleButton>
        <ToggleButton
          bsPrefix="btn btn-sm btn-outline-primary btn-lightness"
          className="btn-lightness track"
          value={ORDER_EXECUTION_VERSION.version2}
        >
          V2
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default OrderExecutionVersionSelector;

OrderExecutionVersionSelector.propTypes = propTypes;
OrderExecutionVersionSelector.defaultProps = defaultProps;
