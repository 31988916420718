import React from 'react';
import { NOTIFICATION_EVENTS } from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';

const propTypes = {
  notification: notificationPropType.isRequired
};
const defaultProps = {};

const { ORDER } = NOTIFICATION_EVENTS;
const { EVENTS } = ORDER;
const {
  ENTRY_ORDERS_FAILED,
  ENTRY_ORDERS_CANCEL_FAILED,
  ENTRY_ORDERS_MODIFY_FAILED,
  EXIT_ORDERS_FAILED,
  EXIT_ORDERS_MODIFY_FAILED,
  STOPLOSS_ORDERS_FAILED,
  STOPLOSS_ORDERS_CANCEL_FAILED
} = EVENTS;

const OrderDetailedMessage = ({ notification }) => {
  const { details, event } = notification;
  const { run_name: fileName, folder_name: folderName } = details;
  const oneIndentation = ' ';

  const signalType = () => {
    switch (event) {
      case ENTRY_ORDERS_FAILED:
        return 'Entry Order(s)';
      case ENTRY_ORDERS_CANCEL_FAILED:
        return 'Unable to cancel entry orders(s)';
      case ENTRY_ORDERS_MODIFY_FAILED:
        return 'Unable to modify entry orders(s)';
      case EXIT_ORDERS_FAILED:
        return 'Exit Order(s)';
      case EXIT_ORDERS_MODIFY_FAILED:
        return 'Unable to modify exit orders(s)';
      case STOPLOSS_ORDERS_FAILED:
        return 'Unable to cancel stoploss orders(s)';
      case STOPLOSS_ORDERS_CANCEL_FAILED:
        return 'Unable to cancel stoploss orders(s)';
      default:
        return '';
    }
  };

  const renderMessage = () => {
    if (!_.includes(_.values(EVENTS), event)) return null;

    return (
      <span>
        <span className="noti-status">
          {signalType()}
          {oneIndentation}
        </span>
        {oneIndentation}
        {_.includes([ENTRY_ORDERS_FAILED, EXIT_ORDERS_FAILED], event) && 'failed'}
        ,
        {oneIndentation}
        <span className="noti-file">
          {`(${fileName})`}
          {oneIndentation}
        </span>
        in
        {oneIndentation}
        <span className="noti-file">
          {`(${folderName})`}
        </span>
      </span>
    );
  };

  return renderMessage();
};

OrderDetailedMessage.defaultProps = defaultProps;
OrderDetailedMessage.propTypes = propTypes;

export default OrderDetailedMessage;
