import { automaticSupportedBrokers, oneClickSupportedBrokers } from 'common/brokerConfigs/config';

export const getBrokerEnabledContent = (currentUserDetails) => {
  const { broker, plan_active_till: planActiveTill, free_trial_till: freeTrialTill } = currentUserDetails;
  const { isInInitialOfferPeriod } = window;

  const isAutomaticSupportUser = _.includes(automaticSupportedBrokers, broker);
  const isOneClickSupportUser = _.includes(oneClickSupportedBrokers, broker);
  let content = 'Yes ';

  const getStrucutureContent = (isCloseSpan) => {
    content += '<span class="status-info">';
    if (isAutomaticSupportUser && isOneClickSupportUser) {
      content += '<b>Automatic</b>';
    } else if (isAutomaticSupportUser) content += '<b>Automatic</b>';

    if (isCloseSpan) content += '</span>';
  };

  if (planActiveTill) {
    getStrucutureContent(true);
    return content;
  }

  if (isInInitialOfferPeriod) {
    getStrucutureContent(false);
    content += ` free trial till ${freeTrialTill}`;
    content += '</span>';
  }

  return content;
};
