import React from 'react';

const Footer = () => {
  const pathname = _.get(window, 'location.pathname', '');
  if (pathname === '/advanced_analytics') return null;

  const blogs = _.get(window, 'applicationDomain.config.blogs', []);

  const renderBlogsContent = _.isEmpty(blogs) ? null : (
    <div className="footer-link mb-2">
      <div className="tx-14"><b>Important Blogs :</b></div>
      {blogs.map(({ title, link }, index) => (
        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="blogs"
          data-track-label="Footer track"
          href={link}
          title={title}
        >
          {title}
          {index !== (blogs.length - 1) && ' |'}
        </a>
      ))}
    </div>
  );

  // must clean up file.. avoid repeating code
  return (
    <div className="footer-section hide-minimal-content">
      <div className="text-muted tx-12 mb-2 text-center">
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        All rights reserved.
        <span className="font-weight-bold ml-1">
          Simply Algo Fintech Private Limited,
        </span>
        <span className="ml-1">
          D Quarters, 48/28, Balan nagar, Peelamedu, Coimbatore - 641004, Tamil
          Nadu
        </span>
      </div>
      <div className="footer-link mb-2">
        <div className="tx-14"><b>Support :</b></div>
        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="aboutus"
          data-track-label="Footer track"
          href="tel:9043044901"
          title="Tamil/English Support"
        >
          <b>Tamil, English :</b>
          {' '}
          9043044901
        </a>

        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="aboutus"
          data-track-label="Footer track"
          href="tel:7200397083"
          title="Hindi Support"
        >
          <b>Hindi :</b>
          {' '}
          7200397083,
        </a>

        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="aboutus"
          data-track-label="Footer track"
          href="tel:7305977320"
          title="Hindi Support"
        >
          7305977320
        </a>

        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="aboutus"
          data-track-label="Footer track"
          href="mailto:support@quantman.in"
          title="Email"
        >
          <b>Email :</b>
          {' '}
          support@quantman.in
        </a>
      </div>

      <div className="footer-link mb-2">
        <div className="tx-14"><b>Business partnership franchise Bulk sales :</b></div>
        <a
          className="track tx-14"
          data-track-category="Footer"
          data-track-action="aboutus"
          data-track-label="Footer track"
          href="tel:7200397082"
          title="Sales Mobile No"
        >
          <b>Mobile :</b>
          {' '}
          7200397082
        </a>
      </div>
      {renderBlogsContent}

      <div className="d-flex align-items-center flex-column justify-content-center gap-10">
        <div className="footer-social">
          <a
            href="https://www.facebook.com/quant.man.16/"
            target="_blank"
            className="social-link d-none d-lg-block track"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="facebook"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <img src="/facebook.svg" alt="facebook" />
              <div className="sub-text">Follow Us</div>
            </div>
          </a>

          <a
            href="https://twitter.com/quantman1"
            target="_blank"
            className="social-link d-none d-lg-block track"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="twitter"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <img src="/twitter.svg" alt="twitter" />
              <div className="sub-text">Follow Us</div>
            </div>
          </a>

          <a
            href="https://t.me/quantman1"
            target="_blank"
            className="social-link d-none d-lg-block track"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="telegram"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <img src="/telegram.svg" className="mw-100" alt="telegram" />
              <div className="sub-text">Join Us</div>
            </div>
          </a>

          <a
            href="https://www.youtube.com/c/quantman"
            target="_blank"
            className="social-link track d-none d-lg-block"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="youtube"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <img src="/youtube.png" className="mw-100" alt="email" />
              <div className="sub-text">Subscribe</div>
            </div>
          </a>

          <a
            href="mailto:contactus@quantman.in"
            target="_blank"
            className="social-link bg-light track"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="email"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <img src="/email.png" className="mw-100" alt="email" />
              <div className="sub-text font-weight-semi">
                contactus@quantman.in
              </div>
            </div>
          </a>

          {/* <a
            href="tel:9043044901"
            target="_blank"
            className="social-link bg-light track"
            rel="noreferrer"
            data-track-category="Footer"
            data-track-action="call"
            data-track-label="Footer track"
          >
            <div className="social-inner">
              <span className="material-icons-outlined tx-16">phone_in_talk</span>
              <div className="sub-text font-weight-semi">9043044901</div>
            </div>
          </a> */}
        </div>

        <div className="">
          <div className="footer-link">
            <a
              className="track flink"
              href="/terms_and_conditions"
              title="Terms & Conditions"
              data-track-category="Footer"
              data-track-action="termandconditions"
              data-track-label="Footer track"
            >
              <u>Terms & Conditions</u>
            </a>
            <span>|</span>
            <a
              href="/faq"
              target="_blank"
              className="track flink"
              title="Faq"
              data-track-category="Footer"
              data-track-action="FAQ"
              data-track-label="Footer track"
              rel="noreferrer"
            >
              <u>Faq</u>
            </a>
            <span>|</span>
            <a
              className="track flink"
              data-track-category="Footer"
              data-track-action="policy"
              data-track-label="Footer track"
              href="/policy"
              title="Policy"
            >
              <u>Policy</u>
            </a>
            <span>|</span>
            <a
              className="track flink"
              data-track-category="Footer"
              data-track-action="aboutus"
              data-track-label="Footer track"
              href="/about_us"
              title="About us"
            >
              <u>About us</u>
            </a>
            <span>|</span>
            <a
              className="track flink"
              data-track-category="Footer"
              data-track-action="bestalgo"
              data-track-label="Footer track"
              href="/best-algo-trading-software"
              title="Best Algo"
            >
              <u>Best Algo</u>
            </a>
            <span>|</span>
            <a
              className="track flink"
              data-track-category="Footer"
              data-track-action="topalgorithmicplatforms"
              data-track-label="Footer track"
              href="/top-10-algo-trading-platforms"
              title="Top 10 Algorithmic Platforms"
            >
              <u>Top 10 Algorithmic Platforms</u>
            </a>

            <span>|</span>
            <a
              className="track flink"
              data-track-category="Footer"
              data-track-action="blog"
              data-track-label="Footer track"
              href="/blog"
              title="Blog"
            >
              <u>Blogs</u>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
