import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import {
  NOTIFICATION_EVENTS, RUN_TYPES
} from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';
import NotificationMessage from './NotificationMessage';
import ViewOrdersPanel from './ViewOrdersPanel';

const propTypes = {
  notification: notificationPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMarkAsOld: PropTypes.func.isRequired,
  signalConfirmation: PropTypes.func.isRequired,
};
const defaultProps = {};

export default class Notification extends React.Component {
  readMessage = () => {
    const { notification, onMarkAsOld } = this.props;
    const isNew = _.get(notification, 'is_new');
    if (isNew) onMarkAsOld(notification);
  }

  renderMarkButton = () => {
    const { onMarkAsOld, notification } = this.props;
    if (notification.is_new) {
      return (
        <button type="button" className="action-btn action-btn-sm" onClick={() => onMarkAsOld(notification)}>
          <i className="material-icons-outlined">drafts</i>
        </button>
      );
    }

    return (
      <button type="button" className="action-btn action-btn-sm" disabled>
        <i className="material-icons-outlined">mark_email_read</i>
      </button>
    );
  }

  renderDeleteButton = () => {
    const { onDelete, notification } = this.props;

    return (
      <button
        className="delete-btn delete-btn-sm"
        type="button"
        onClick={() => onDelete(notification)}
      >
        <i className="material-icons-outlined">delete_outline</i>
      </button>
    );
  }

  renderFooter = () => {
    const { notification } = this.props;
    const { created_at: createdAt } = notification;
    const timeFormatShow = moment(createdAt).format('LTS');
    const monthFormatShow = moment(createdAt).format('ll');

    return (
      <div className="notification-footer d-flex align-items-center justify-content-between">
        <div className="tx-10 text-muted">
          {timeFormatShow}
          &nbsp;
          -
          &nbsp;
          {monthFormatShow}
        </div>
        <div className="d-flex align-items-center gap-10">
          {this.renderMarkButton()}
          {this.renderDeleteButton()}
        </div>
      </div>
    );
  }

  renderMessage() {
    const { notification } = this.props;
    const linkAttrs = {};

    return (
      <a
        href={notification.url}
        {...linkAttrs}
        className="notification-details"
        onClick={() => this.readMessage()}
      >
        <NotificationMessage notification={notification} />
      </a>
    );
  }

  render() {
    const { notification } = this.props;
    const { notifiable_type: notifiableType, details } = notification;
    const { run_type: runType } = details;
    const {
      RUN: {
        NAME: run
      }
    } = NOTIFICATION_EVENTS;
    const { historic, live } = RUN_TYPES;

    const notificationClassNames = classNames('list-group-item notification-list-item', {
      'unread-card': notification.is_new,
      active: !notification.is_new,
      'not-backtest': (notifiableType === run && runType === historic),
      'not-live': (notifiableType === run && runType === live)
    });

    return (
      <li className={notificationClassNames}>
        {this.renderMessage()}
        <ViewOrdersPanel notification={notification} />
        {this.renderFooter()}
      </li>
    );
  }
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;
