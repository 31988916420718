import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes, signalsPropTypes } from 'common/propTypes';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import {
  OutputSchemaUtility,
  TypeheadSchemaUtility,
} from 'v2/common/quantConfig/outputSchema/utility';
import { schemaTypes } from 'v2/common/quantConfig/outputSchema/config';
import withHiddenSignal from 'common/components/withHiddenSignal/index';
import HoverMessage from 'common/components/HoverMessage';
import { signalTypes } from 'v2/modules/withRunForm/configs/index';
import { OperandUtility } from '../utility';

let defaultParentClassName = 'border position-relative rounder p-2 d-flex ';
defaultParentClassName += 'align-items-center justify-content-between mb-2';

const propTypes = {
  signals: signalsPropTypes.isRequired,
  onEdit: PropTypes.func,
  outputSchema: outputSchemaPropTypes.isRequired,
  isViewOnly: PropTypes.bool,
  label: PropTypes.string,
  parentClassName: PropTypes.string,
  signalType: PropTypes.string,
  hiddenSignal: PropTypes.bool.isRequired,
};
const defaultProps = {
  isViewOnly: false,
  onEdit: () => {},
  label: '',
  signalType: '',
  parentClassName: defaultParentClassName,
};

const SignalsView = ({
  signals,
  onEdit,
  outputSchema,
  isViewOnly,
  parentClassName,
  label,
  signalType,
  hiddenSignal
}) => {
  const namePairLabel = _.reduce(
    outputSchema,
    (acc, schema) => {
      acc[schema.name] = TypeheadSchemaUtility.getOption(schema)?.label || schema.label;
      return acc;
    },
    {}
  );

  const getModifiedRightOperand = (rightOperand) => {
    const isArray = _.isArray(rightOperand);
    if (isArray) {
      return _.chain(rightOperand)
        .map((operand) => _.startCase(operand))
        .join(', ')
        .value();
    }
    return rightOperand;
  };

  const signalContent = useMemo(() => ({
    [signalTypes.entrySignal]:
    'Entry conditions help you decide when to enter a trade. For example: Current close is above Super trend',
    [signalTypes.exitSignal]:
    'Exit conditions help you decide when to exit a trade. For example: Current close is below Super trend'
  }), []);

  const renderSignal = (signal, idx) => {
    const {
      leftOperand, conditionOperator, rightOperand, operator
    } = signal;
    const leftOperandType = OutputSchemaUtility.findByName(
      outputSchema,
      leftOperand
    )?.type;
    const { label: operatorLabel } = _.find(
      OperandUtility.getOperators(leftOperandType),
      ({ name }) => name === operator
    ) || {};
    const isBoolean = leftOperandType === schemaTypes.boolean;
    const { name } = OutputSchemaUtility.findByName(outputSchema, rightOperand);

    let rightOperandLabel = name || rightOperand;
    if (isBoolean) {
      if (Number(rightOperand) === 0) rightOperandLabel = 'False';
      else if (Number(rightOperand) === 1) rightOperandLabel = 'True';
    }

    return (
      <div key={idx} className="d-flex align-items-center">
        {idx !== 0 && (
          <span className="font-weight-medium tx-12 mx-2 condition-oprtator">
            {_.upperCase(conditionOperator)}
          </span>
        )}
        <span className="tx-12 condition-info">
          {ReactHtmlParser(
            _.get(namePairLabel, leftOperand, _.startCase(leftOperand))
          )}
        </span>
        <u className="font-weight-medium tx-12 mx-2 underline-operator">
          {_.startCase(operatorLabel || operator)}
        </u>
        <span className="tx-12 condition-info">
          {ReactHtmlParser(
            _.get(
              namePairLabel,
              rightOperandLabel,
              getModifiedRightOperand(rightOperandLabel)
            )
          )}
        </span>
      </div>
    );
  };

  const SignalsViewContent = (() => {
    return (
      <div className="d-flex align-items-center signals-info gap-3">
        {_.isEmpty(signals) ? (
          <span className="tx-13">
            <span className="tx-12 condition-info">Is Empty</span>
          </span>
        ) : (
          _.map(signals, renderSignal)
        )}
      </div>
    );
  });

  const SignalsViewContentWithHidden = withHiddenSignal(SignalsViewContent, hiddenSignal);

  const renderSignalsViewEditButton = useMemo(() => {
    return (
      <button
        type="button"
        disabled={hiddenSignal}
        className="btn btn-edit-outline btn-sm edit-btn"
        onClick={() => {
          if (!hiddenSignal) onEdit();
        }}
      >
        <i className="edit-icon mr-1" />
        <span>Edit</span>
      </button>
    );
  }, [hiddenSignal, onEdit]);

  return (
    <div className={parentClassName}>
      <div className="d-flex align-items-center flex-wrap overflow-auto signal-container">
        {label && (
          <span className="font-weight-bold tx-13 signals-type">
            {label}
          </span>
        )}
        {signalContent[signalType]
        && (
        <HoverMessage title={label} message={signalContent[signalType]}>
          <span className="material-icons-outlined info-icon ml-1 mr-1">
            info
          </span>
        </HoverMessage>
        )}
        <SignalsViewContentWithHidden
          signals={signals}
          renderSignal={renderSignal}
        />
      </div>
      {!isViewOnly && (
        hiddenSignal ? (
          <HoverMessage message="This action has been disabled by the Author">
            {renderSignalsViewEditButton}
          </HoverMessage>
        ) : (
          renderSignalsViewEditButton
        )
      )}
    </div>
  );
};

SignalsView.propTypes = propTypes;
SignalsView.defaultProps = defaultProps;

export default SignalsView;
