import React, { useState } from 'react';
import { NOTIFICATION_EVENTS } from 'common/constants';
import { fetchOrdersByOrderId } from 'common/api/signalConfirmation';
import notificationPropType from './propTypes/notificationPropType';
import OrderDetailsInModal from './OrderDetailsInModal';

const propTypes = {
  notification: notificationPropType.isRequired
};

const defaultProps = {};

const ViewOrdersPanel = ({ notification }) => {
  const [isModal, hasShowModal] = useState(false);
  const [orders, setOrders] = useState([]);

  const { notifiables } = notification;
  const notifiableType = notifiables[0].notifiable_type;

  if (![NOTIFICATION_EVENTS.ORDER.NAME, NOTIFICATION_EVENTS.ORDERV2.NAME].includes(notifiableType)) {
    return null;
  }

  const notifiableIds = notifiables.map(({ notifiable_id: notifiableId }) => notifiableId);

  const {
    details: {
      job_id: jobId,
      run_name: runName,
      order_type: orderType
    }
  } = notification;

  const fetchOrdersDetails = () => {
    fetchOrdersByOrderId({ jobId, orderIds: notifiableIds })
      .then((response) => {
        setOrders(response);
        hasShowModal(true);
      });
  };

  return (
    <div className="notification-footer d-flex align-items-center justify-content-between">
      <span
        className="btn btn-link btn-sm p-0"
        onClick={() => fetchOrdersDetails()}
      >
        Show Details
      </span>
      {isModal && (
        <OrderDetailsInModal
          orders={orders}
          orderType={orderType}
          title={runName}
          onClose={() => {
            hasShowModal(false);
            setOrders([]);
          }}
        />
      )}
    </div>
  );
};

ViewOrdersPanel.defaultProps = defaultProps;
ViewOrdersPanel.propTypes = propTypes;

export default ViewOrdersPanel;
