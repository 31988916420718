import PropTypes from 'prop-types';
import { useEffect } from 'react';

const propTypes = {
  message: PropTypes.string.isRequired,
};

const defaultProps = {};

const ScriptLoad = ({ message }) => {
  useEffect(() => {
    const scripts = message?.match(/<script\b[^>]*>([\s\S]*?)<\/script>/g);
    if (!scripts) return null;

    const scriptTags = scripts.map((script) => {
      const scriptContent = script.replace(/<script\b[^>]*>/, '').replace(/<\/script>/, '');
      const scriptTag = document.createElement('script');
      scriptTag.textContent = scriptContent;
      document.body.appendChild(scriptTag);
      return scriptTag;
    });

    // Cleanup function to remove the script tags when the component unmounts
    return () => {
      scriptTags.forEach((scriptTag) => {
        document.body.removeChild(scriptTag);
      });
    };
  }, [message]); // Ensure the effect re-runs if 'message' changes

  return null; // Return nothing, as you're handling the side effect with the script tags
};

ScriptLoad.propTypes = propTypes;
ScriptLoad.defaultProps = defaultProps;

export default ScriptLoad;
