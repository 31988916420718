import React from 'react';
import { NOTIFICATION_EVENTS } from 'common/constants';
import notificationPropType from './propTypes/notificationPropType';
import RunDetailNotificationMessage from './RunDetailNotificationMessage';
import OrderDetailedMessage from './OrderDetailedMessage';

const propTypes = {
  notification: notificationPropType.isRequired,
};

const defaultProps = {};

const NotificationMessage = ({ notification }) => {
  const { notifiables } = notification;
  const notifiableType = notifiables[0].notifiable_type;

  const renderMessage = () => {
    switch (notifiableType) {
      case NOTIFICATION_EVENTS.RUN.NAME:
        return <RunDetailNotificationMessage notification={notification} />;
      case NOTIFICATION_EVENTS.ORDER.NAME:
      case NOTIFICATION_EVENTS.ORDERV2.NAME:
        return <OrderDetailedMessage notification={notification} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="icons" />
      {renderMessage()}
    </>
  );
};

NotificationMessage.propTypes = propTypes;
NotificationMessage.defaultProps = defaultProps;

export default NotificationMessage;
