import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  notifiables: PropTypes.arrayOf(PropTypes.shape({
    notifiable_type: PropTypes.string.isRequired,
    notifiable_id: PropTypes.string.isRequired,
  })).isRequired,
  event: PropTypes.string.isRequired,
  details: PropTypes.shape({}),
  is_new: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
});
